import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './routes';
import { SnackbarProvider } from 'notistack';
import './index.css';
import '@fontsource/roboto';
import { createTheme, ThemeProvider } from '@mui/material';
import { QueryClient, QueryClientProvider } from 'react-query';
import { SnackbarUtilsConfigurator } from './utils/snackbar/snackbar-utils';
import AppProvider from './hooks/context';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
);

const theme = createTheme({
    palette: {
        primary: {
            main: '#069FEA',
        },
        secondary: {
            main: '#BAB06E',
        },
    },
    typography: {
        fontFamily: 'Roboto',
    },
});

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <SnackbarProvider>
                <SnackbarUtilsConfigurator />
                <ThemeProvider theme={theme}>
                    <AppProvider>
                        <App />
                    </AppProvider>
                </ThemeProvider>
            </SnackbarProvider>
            {/*<ReactQueryDevtools initialIsOpen={false} />*/}
        </QueryClientProvider>
    </React.StrictMode>,
);
