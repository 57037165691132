import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { StatisticType } from '../types/statistic-types';
import { titleSuffix } from './constants';
import moment from 'moment-timezone';

export const setDocumentTitle = (title: string) => {
    if (!title) {
        document.title = titleSuffix;
        return;
    }
    document.title = `${title} | ${titleSuffix}`;
};

export const loadTitle = () => {
    document.title = `... | ${titleSuffix}`;
};

export const useURLQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
};

export const getRGBColor = () => {
    const randomBetween = (min, max) =>
        min + Math.floor(Math.random() * (max - min + 1));
    const r = randomBetween(0, 255);
    const g = randomBetween(0, 255);
    const b = randomBetween(0, 255);
    return `rgb(${r},${g},${b} $REPLACE)`;
};

export const defaultGraphColors = [
    'rgba(6, 159, 234 $REPLACE)',
    'rgba(255, 0, 0 $REPLACE)',
    'rgba(0, 255, 0 $REPLACE)',
    'rgba(255, 165, 0 $REPLACE)',
    'rgba(128, 0, 128 $REPLACE)',
];

export const getGraphColor = (i: number) =>
    defaultGraphColors[i] || getRGBColor();

export const graphTranslate = (
    statisticType: StatisticType,
    username?: string,
) => {
    switch (statisticType) {
        case StatisticType.CALL_DURATION_ONE_MINUTE:
            return 'Anrufe länger als 1 Minute';
        case StatisticType.DATE_CREATED:
            return 'Neue Leads';
        case StatisticType.QUALIFICATION_CALL:
            if (username === 'pflegefinanzen') {
                return 'Infogespräch terminiert';
            }
            if (username === 'diveinourworld') {
                return 'DC terminiert';
            }
            return 'Qualifizierungsgespräche terminiert';
        case StatisticType.QUALIFICATION_CALL_NO_SHOW:
            if (username === 'pflegefinanzen') {
                return 'Infogespräch nicht erschienen';
            }
            if (username === 'diveinourworld') {
                return 'DC nicht erschienen';
            }
            return 'Qualifizierungsgespräch nicht erschienen';
        case StatisticType.QUALIFICATION_CALL_SHOW:
            if (username === 'pflegefinanzen') {
                return 'Infogespräch stattgefunden';
            }
            if (username === 'diveinourworld') {
                return 'DC stattgefunden';
            }
            return 'Qualifizierungsgespräch stattgefunden';
        case StatisticType.FIRST_SALES_CALL:
            if (username === 'pflegefinanzen') {
                return 'Phasengespräch terminiert';
            }
            if (username === 'diveinourworld') {
                return 'SC terminiert';
            }
            return '1. Verkaufsgespräch terminiert';
        case StatisticType.FIRST_SALES_CALL_NO_SHOW:
            if (username === 'pflegefinanzen') {
                return 'Phasengespräch nicht erschienen';
            }
            if (username === 'diveinourworld') {
                return 'SC nicht erschienen';
            }
            return '1. Verkaufsgespräch nicht erschienen';
        case StatisticType.FIRST_SALES_CALL_SHOW:
            if (username === 'pflegefinanzen') {
                return 'Phasengespräch stattgefunden';
            }
            if (username === 'diveinourworld') {
                return 'SC stattgefunden';
            }
            return '1. Verkaufsgespräch stattgefunden';
        case StatisticType.SECOND_SALES_CALL:
            if (username === 'pflegefinanzen') {
                return 'Konzeptgespräch terminiert';
            }
            if (username === 'diveinourworld') {
                return 'Followup terminiert';
            }
            return '2. Verkaufsgespräch terminiert';
        case StatisticType.SECOND_SALES_CALL_NO_SHOW:
            if (username === 'pflegefinanzen') {
                return 'Konzeptgespräch nicht erschienen';
            }
            if (username === 'diveinourworld') {
                return 'Followup nicht erschienen';
            }
            return '2. Verkaufsgespräch nicht erschienen';
        case StatisticType.SECOND_SALES_CALL_SHOW:
            if (username === 'pflegefinanzen') {
                return 'Konzeptgespräch stattgefunden';
            }
            if (username === 'diveinourworld') {
                return 'Followup stattgefunden';
            }
            return '2. Verkaufsgespräch stattgefunden';
        case StatisticType.CALL_DURATION_ONE_MINUTE_QUALIFICATION_CALL_RATE:
            if (username === 'pflegefinanzen') {
                return 'Termin Rate Infogespräch von Gesprächen > 1 min';
            }
            if (username === 'diveinourworld') {
                return 'Termin Rate DC von Gesprächen > 1 min';
            }
            return 'Termin Rate Qualifizierung von Gesprächen > 1 min';
        case StatisticType.QUALIFICATION_CALL_SHOW_RATE:
            if (username === 'pflegefinanzen') {
                return 'Erscheinungsquote Infogespräch';
            }
            if (username === 'diveinourworld') {
                return 'Erscheinungsquote DC';
            }
            return 'Erscheinungsquote Qualifizierung';
        case StatisticType.QUALIFICATION_CALL_TO_SALES_CALL_RATE:
            if (username === 'pflegefinanzen') {
                return 'Termin Rate Phasengespräch von Infogespräch';
            }
            if (username === 'diveinourworld') {
                return 'Termin Rate SC von DC';
            }
            return 'Termin Rate Verkaufsgespräche von Qualifizierung';
        case StatisticType.FIRST_SALES_CALL_SHOW_RATE:
            if (username === 'pflegefinanzen') {
                return 'Erscheinungsquote Phasengespräch';
            }
            if (username === 'diveinourworld') {
                return 'Erscheinungsquote SC';
            }
            return 'Erscheinungsquote 1. Verkaufsgespräch';
        case StatisticType.SECOND_SALES_CALL_SHOW_RATE:
            if (username === 'pflegefinanzen') {
                return 'Erscheinungsquote Konzeptgespräch';
            }
            if (username === 'diveinourworld') {
                return 'Erscheinungsquote Followup';
            }
            return 'Erscheinungsquote 2. Verkaufsgespräch';
        case StatisticType.WON_OPPORTUNITIES:
            return 'Gewonnene Kunden';
        case StatisticType.LOST_OPPORTUNITIES:
            return 'Verlorene Kunden';
        case StatisticType.SALES_CALL_WIN_RATE:
            return 'Abschlussquote';
        case StatisticType.CALLS:
            return 'Anrufe gesamt';
        case StatisticType.OUTBOUND_CALLS:
            return 'Ausgehende Anrufe';
        case StatisticType.ANSWERED_CALLS:
            return 'Beantwortete Anrufe';
        case StatisticType.AVG_FIRST_PAYMENT:
            return 'Durchschnittliche erste Rate';
        default:
            return '';
    }
};

export const getFromDateByTimeframe = (timeframe: string) => {
    switch (timeframe) {
        case 'DAYS':
            return moment.utc().subtract(7, 'days');
        case 'WEEKS':
            return moment.utc().subtract(6, 'weeks').startOf('week');
        case 'MONTH':
        default:
            return moment.utc().subtract(6, 'months').startOf('month');
    }
};

// export const getStatusDataWithOriginData = (
//     type: StatisticType,
//     leadData: any[],
//     demo: boolean = false,
//     username: string,
// ): { label: string; data: any } => {
//     let label = graphTranslate(type, context.user?.username.toLowerCase());
//     const formattedData: any = {};
//     RateToOriginMapping[type].forEach((qk, i) => {
//         const d = leadData[qk];
//         if (i === 0) {
//             formattedData.first = {
//                 current: d.current || 0,
//                 last: d.last || 0,
//             };
//         } else {
//             formattedData.second = {
//                 current: d.current || 0,
//                 last: d.last || 0,
//             };
//         }
//     });
//     let calculatedCurrent: any;
//     let calculatedLast: any;
//     if (formattedData.first && formattedData.second) {
//         if (
//             formattedData.first.current === 0 &&
//             formattedData.second.current === 0
//         ) {
//             calculatedCurrent = '-';
//         } else {
//             const amount = Math.round(
//                 (formattedData.first.current / formattedData.second.current) *
//                     100,
//             );

//             if (amount > 100) {
//                 calculatedCurrent = 'Ungültige Quote';
//                 if (demo) {
//                     calculatedCurrent = Math.round(
//                         (formattedData.second.current /
//                             formattedData.first.current) *
//                             100,
//                     );
//                     const first = formattedData.first.current;
//                     formattedData.first.current = formattedData.second.second;
//                     formattedData.second.current = first;
//                 }
//             } else {
//                 calculatedCurrent = `${amount}`;
//             }
//         }

//         if (formattedData.first.last === 0 && formattedData.second.last === 0) {
//             calculatedLast = '-';
//         } else {
//             calculatedLast = Math.round(
//                 (formattedData.first.last / formattedData.second.last) * 100,
//             );
//         }
//     }

//     return {
//         label,
//         data: { current: calculatedCurrent, last: calculatedLast },
//     };
// };
