import { createBrowserHistory } from 'history';

export const appHistory = createBrowserHistory();
export const authCookieName = 'auth';
export const hideOnSmallScreen = {
    xs: 'none',
    sm: 'none',
    md: 'inline-flex',
    lg: 'inline-flex',
    xl: 'inline-flex',
};
export const showOnlySmallScreen = {
    xs: 'inline-flex',
    sm: 'inline-flex',
    md: 'none',
    lg: 'none',
    xl: 'none',
};
export const titleSuffix = 'Salesby GmbH';
export const isLocalhost = window.location.hostname === 'localhost';
