import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import SalesbyLogo from '../../assets/salesby-logo.png';

interface HeaderProps {
    title?: string;
    subTitle?: string;
    m?: number;
}

const Header: FC<HeaderProps> = (props) => {
    return (
        <Box sx={{ m: props.m, mt: 0 }} display="flex">
            <Box>
                <img
                    height="45"
                    style={{ marginLeft: 5, marginRight: 5 }}
                    src={SalesbyLogo}
                    alt="Logo"
                />
            </Box>
            <Box sx={{ mt: 'auto', mb: 'auto', ml: 2 }}>
                <Typography
                    variant="h5"
                    component="h5"
                    color="#000"
                    fontWeight="bold"
                >
                    {props.title}
                    <Typography color="#000" sx={{ lineHeight: 0.5 }}>
                        {props.subTitle}
                    </Typography>
                </Typography>
            </Box>
        </Box>
    );
};

export default Header;
