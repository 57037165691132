import { FC, useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AppContext } from '../hooks/context';

const AuthenticatedRoute: FC = () => {
    const context = useContext(AppContext);

    return context.user || context.isAuthenticated ? (
        <Outlet />
    ) : (
        <Navigate to="/plecto" />
    );
};

export default AuthenticatedRoute;
