import { FC, useContext, useEffect, useState } from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from '@mui/material';
import LoginInput from '../login/login-input';
import snackbarUtils from '../../utils/snackbar/snackbar-utils';
import { useQueryClient } from 'react-query';
import { AdminApi } from '../../api';
import { AppContext } from '../../hooks/context';

export interface User {
    id: string;
    username: string;
    role: string;
}

interface CreateEditUserDialogProps {
    isEdit: boolean;
    existingUser?: User;
    open: boolean;
    onClose: () => void;
}

const CreateEditUserDialog: FC<CreateEditUserDialogProps> = (
    props: CreateEditUserDialogProps,
) => {
    const { open, onClose, isEdit, existingUser } = props;
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [role, setRole] = useState('ADMIN');
    const [loading, setLoading] = useState(false);
    const queryClient = useQueryClient();
    const context = useContext(AppContext);

    useEffect(() => {
        if (isEdit && existingUser) {
            setUsername(existingUser.username);
            setRole(existingUser.role);
        }
    }, [isEdit, existingUser]);

    const createUser = async () => {
        if (username && password) {
            setLoading(true);
            try {
                const d = await AdminApi.createUser(context.authToken, {
                    username,
                    password,
                    role,
                });
                setLoading(false);
                if (d) {
                    closeAndReload();
                }
            } catch (e) {
                setLoading(false);
            }
        }
    };

    const closeAndReload = () => {
        resetState();
        queryClient.invalidateQueries('getUsers');
        onClose();
    };

    const resetState = () => {
        setUsername('');
        setPassword('');
        setRole('ADMIN');
    };

    const editUser = async () => {
        if (
            existingUser &&
            (username !== existingUser?.username ||
                role !== existingUser?.role ||
                password)
        ) {
            if (password && password.length < 8) {
                snackbarUtils.warning(
                    'Das Passwort muss mindestens 8 Zeichen lang sein',
                );
                return;
            }
            setLoading(true);
            const update: any = {};
            if (existingUser.username !== username) {
                update.username = username;
            }
            if (password) {
                update.password = password;
            }
            if (existingUser.role !== role) {
                update.role = role;
            }
            try {
                const d = await AdminApi.editUser(
                    context.authToken,
                    existingUser.id,
                    update,
                );
                setLoading(false);
                if (d) {
                    closeAndReload();
                }
            } catch (e) {
                setLoading(false);
            }
        } else {
            snackbarUtils.success('Keine Änderungen vorgenommen');
            resetState();
            onClose();
        }
    };

    return (
        <Dialog
            open={open}
            onClose={() => {
                resetState();
                onClose();
            }}
        >
            <DialogTitle>
                {isEdit ? 'Benutzer bearbeiten' : 'Benutzer erstellen'}
            </DialogTitle>
            <DialogContent>
                <LoginInput
                    onChange={setUsername.bind(this)}
                    label="Benutzername"
                    initialValue={username}
                />
                <LoginInput
                    label="Passwort"
                    onChange={setPassword.bind(this)}
                    initialValue={password}
                    helperText={`Mindestens 8 Zeichen${
                        isEdit ? '. Überschreibt das aktuelle Passwort!' : ''
                    }`}
                    password
                />
                <Box display="flex" justifyContent="center" sx={{ mt: 1 }}>
                    <FormControl>
                        <FormControl fullWidth>
                            <InputLabel>Rolle</InputLabel>
                            <Select
                                value={role}
                                label="Rolle"
                                onChange={(e: any) => setRole(e?.target?.value)}
                                sx={{ width: '22rem' }}
                            >
                                <MenuItem value={'ADMIN'}>ADMIN</MenuItem>
                                <MenuItem value={'SUPERADMIN'}>
                                    SUPERADMIN
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </FormControl>
                </Box>
                <Box
                    display="flex"
                    justifyContent="center"
                    flexDirection="column"
                    sx={{ mt: 2 }}
                >
                    <Button
                        color="primary"
                        variant="contained"
                        id="loginBtn"
                        onClick={() => {
                            if (isEdit) {
                                editUser();
                            } else {
                                createUser();
                            }
                        }}
                        className="formBtn"
                        disabled={
                            username === '' ||
                            (!isEdit &&
                                (password === '' || password.length < 8))
                        }
                        sx={{ color: 'white' }}
                    >
                        {loading ? (
                            <CircularProgress sx={{ color: 'white' }} />
                        ) : isEdit ? (
                            'Benutzer bearbeiten'
                        ) : (
                            'Benutzer erstellen'
                        )}
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default CreateEditUserDialog;
