import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import { Navbar } from '../../components';
import { loadTitle, setDocumentTitle } from '../../utils/misc-utils';

const PlectoInfo = () => {
    // useEffect(() => {
    //     if (context.isAuthenticated) {
    //         navigate('/');
    //     }
    // }, [context.isAuthenticated, navigate]);

    useEffect(() => {
        setDocumentTitle('Salesby Dashboard = Plecto');
        return () => loadTitle();
    }, []);

    return (
        <Box>
            <Navbar title="" />
            <Box
                display="flex"
                flexDirection="column"
                sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '50%',
                    ml: 'auto',
                    mr: 'auto',
                }}
            >
                <Typography variant="h5" textAlign="center">
                    Die Salesby Dashboard Plattform wurde durch Plecto
                    Dashboards abgel&ouml;st.
                    <br />
                    Wende dich bei Fragen bitte an{' '}
                    <a href="mailto:kundensupport@salesby.de">
                        kundensupport@salesby.de
                    </a>
                </Typography>
            </Box>
        </Box>
    );
};

export default PlectoInfo;
