import axios from 'axios';
import { authCookieName, isLocalhost } from '../constants';
import { deleteCookie } from '../cookie-utils';
import snackbarUtils from '../snackbar/snackbar-utils';

export const backendUrl = isLocalhost ? 'http://localhost:3001/' : undefined;

export const axiosInstance = axios.create({
    baseURL: backendUrl,
});

axiosInstance.interceptors.response.use(
    (res) => {
        if (!res.data.warning && res.data.message) {
            snackbarUtils.success(res.data.message);
        } else if (res.data.warning) {
            snackbarUtils.warning(res.data.warning.message);
        }
        return res;
    },
    async (err) => {
        if (
            err.response?.status === 401 &&
            !window.location.href.includes('/login')
        ) {
            deleteCookie(authCookieName);
            snackbarUtils.error('Session abgelaufen');
            window.location.href = '/login';
        } else if (err.response?.data?.detail) {
            const detail = err.response?.data?.detail;
            if (Array.isArray(detail)) {
                detail.forEach((d) => snackbarUtils.error(d));
            } else {
                snackbarUtils.error(detail);
            }
        } else {
            snackbarUtils.error(
                'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut!',
            );
        }

        return Promise.reject(err);
    },
);
