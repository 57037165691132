import React, { createContext, useEffect, useState } from 'react';
import { IAppContext } from '../types';
import { authCookieName } from '../utils/constants';
import { getCookie, setCookie } from '../utils/cookie-utils';
import { TokenData } from '../types/token-data';
import { decodeToken } from 'react-jwt';
import { deleteCookie } from '../utils/cookie/cookie-utils';

const authToken = getCookie(authCookieName);
const isAuthenticated = authToken !== undefined;

const user: TokenData | null = decodeToken(authToken || '');

const initialContext: IAppContext = {
    authToken,
    user,
    isAuthenticated,
    isAdmin: false,
    isSuperAdmin: false,
    authenticate: (authToken: string) => console.log(authToken),
    setUser: (user: TokenData | null | undefined) => console.log(user),
    signout: () => console.log('Signed out'),
    username: '',
    setUsername: (username: string) => console.log(username),
};

export const AppContext = createContext<IAppContext>(initialContext);

const AppProvider = (props: { children: React.ReactNode }) => {
    const [authToken, setAuthToken] = useState(initialContext.authToken);
    const [isAuthenticated, setIsAuthenticated] = useState(
        initialContext.isAuthenticated,
    );
    const [user, setUser] = useState(initialContext.user);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);
    const [username, setUsername] = useState('');

    useEffect(() => {
        const userData: TokenData | null = decodeToken(authToken || '');
        if (userData) {
            if (userData.role) {
                if (userData.role === 'ADMIN') {
                    setIsAdmin(true);
                } else if (userData.role === 'SUPERADMIN') {
                    setIsSuperAdmin(true);
                    setIsAdmin(true);
                } else {
                    setIsAdmin(false);
                    setIsSuperAdmin(false);
                }
            }
            setUsername(userData.username);
        }
        setUser(userData);
    }, [authToken]);

    const authenticate = (authToken: string) => {
        setCookie(authCookieName, authToken);
        setIsAuthenticated(true);
        setAuthToken(authToken);
    };

    const signout = () => {
        deleteCookie(authCookieName);
        setAuthToken(undefined);
        setIsAuthenticated(false);
        setIsAdmin(false);
        setIsSuperAdmin(false);
    };

    return (
        <AppContext.Provider
            value={{
                ...initialContext,
                isAuthenticated,
                isAdmin,
                isSuperAdmin,
                authToken,
                user,
                setUser,
                authenticate,
                signout,
                username,
                setUsername,
            }}
        >
            {props.children}
        </AppContext.Provider>
    );
};

export default AppProvider;
