import {
    AppBar,
    Box,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Toolbar,
    Typography,
} from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../hooks/context';
import { Header } from '..';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBars,
    faArrowRightFromBracket,
    faUsers,
    faClone,
    faArrowRightToBracket,
} from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import snackbarUtils from '../../utils/snackbar/snackbar-utils';

interface NavbarProps {
    title?: string;
    subTitle?: string;
    addClient?: boolean;
    search?: boolean;
    hideImpersonate?: boolean;
}

const Navbar: FC<NavbarProps> = (props) => {
    const context = useContext(AppContext);
    const { isAuthenticated } = context;
    const navigate = useNavigate();
    const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
    const menuOpen = Boolean(menuAnchor);

    const [menuItems, setMenuItems] = useState<any[]>([]);

    const performSignout = async () => {
        try {
            context.signout();
            snackbarUtils.success('Erfolgreich ausgeloggt');
        } catch (error) {
            console.error(error);
            snackbarUtils.error(
                'Da ist leider etwas schief gelaufen. Bitte versuchen Sie es später erneut.',
            );
        }
    };

    useEffect(() => {
        if (isAuthenticated) {
            setMenuItems([
                {
                    label: 'Benutzerverwaltung',
                    action: () => navigate('/admin/users'),
                    pathname: '/admin/users',
                    icon: faUsers,
                    superAdminOnly: true,
                    enabled: true,
                },
                {
                    label: 'Klonen',
                    action: () => navigate('/admin/clone'),
                    pathname: '/admin/clone',
                    icon: faClone,
                    adminOnly: true,
                    enabled: true,
                },
                {
                    label: 'Logout',
                    action: () => performSignout(),
                    icon: faArrowRightFromBracket,
                    enabled: true,
                },
            ]);
        } else {
            setMenuItems([
                {
                    label: 'Admin Login',
                    action: () => navigate('/admin/login'),
                    icon: faArrowRightToBracket,
                    enabled: window.location.pathname !== '/admin/login',
                },
            ]);
        }
    }, [isAuthenticated]);

    return (
        <AppBar position="static" color="transparent" sx={{ mb: 2 }}>
            <Toolbar>
                <Box
                    sx={{
                        flexGrow: 1,
                        alignItems: 'center',
                        textTransform: 'uppercase',
                        cursor: 'pointer',
                    }}
                    onClick={() => navigate('/upload')}
                >
                    <Header title={props.title} subTitle={props.subTitle} />
                </Box>
                <Box flexGrow="1"></Box>

                <Box>
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: 'none', md: 'flex' },
                        }}
                    >
                        {menuItems.map((item) => {
                            if (item.adminOnly && !context.isAdmin) {
                                return <span key={item.label} />;
                            }
                            if (item.superAdminOnly && !context.isSuperAdmin) {
                                return <span key={item.label} />;
                            }
                            if (!item.enabled) {
                                return null;
                            }
                            return (
                                <MenuItem
                                    key={item.label}
                                    onClick={item.action}
                                    //disabled={item.disabled}
                                >
                                    {item.icon && (
                                        <ListItemIcon>
                                            <FontAwesomeIcon icon={item.icon} />
                                        </ListItemIcon>
                                    )}
                                    <ListItemText>
                                        <Typography
                                            fontWeight={
                                                item.pathname &&
                                                window.location.pathname ===
                                                    item.pathname
                                                    ? 'bold'
                                                    : 'regular'
                                            }
                                        >
                                            {item.label}
                                        </Typography>
                                    </ListItemText>
                                </MenuItem>
                            );
                        })}
                    </Box>
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: 'flex', md: 'none' },
                        }}
                    >
                        <IconButton
                            size="large"
                            color="inherit"
                            aria-label="menu"
                            onClick={(event) =>
                                setMenuAnchor(event.currentTarget)
                            }
                        >
                            <FontAwesomeIcon icon={faBars} />
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: 'flex', md: 'none' },
                        }}
                    >
                        <Menu
                            id="menu-appbar"
                            anchorEl={menuAnchor}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={menuOpen}
                            onClose={() => setMenuAnchor(null)}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {menuItems.map((item) => {
                                if (item.adminOnly && !context.isAdmin) {
                                    return <span key={item.label} />;
                                }
                                if (!item.enabled) {
                                    return null;
                                }
                                return (
                                    <MenuItem
                                        key={item.label}
                                        onClick={item.action}
                                        //disabled={item.disabled}
                                    >
                                        {item.icon && (
                                            <ListItemIcon>
                                                <FontAwesomeIcon
                                                    icon={item.icon}
                                                />
                                            </ListItemIcon>
                                        )}
                                        <ListItemText>
                                            {item.label}
                                        </ListItemText>
                                    </MenuItem>
                                );
                            })}
                        </Menu>
                    </Box>
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default Navbar;
