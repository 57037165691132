import { FC, useContext, useEffect } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { AppContext } from '../hooks/context';

const AdminRoute: FC = () => {
    const context = useContext(AppContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (context.isAuthenticated && !context.isAdmin) {
            navigate('/');
        }
    }, [context.isAuthenticated, context.isAdmin, navigate]);

    return context.isAdmin ? <Outlet /> : <Navigate to="/" />;
};

export default AdminRoute;
