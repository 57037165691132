import { axiosInstance } from '../../utils/api/api-utils';

export default class AdminApi {
    static getUsers(token: string | undefined): Promise<any> {
        return axiosInstance
            .get('/api/admin/user', {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
                return res.data;
            });
    }

    static createUser(token: string | undefined, body: any): Promise<any> {
        return axiosInstance
            .post('/api/admin/user', body, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
                return res.data;
            });
    }

    static editUser(
        token: string | undefined,
        userId: string,
        body: any,
    ): Promise<any> {
        return axiosInstance
            .post(`/api/admin/user/${userId}`, body, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
                return res.data;
            });
    }

    static cloneCloseUser(
        token: string | undefined,
        // userId: string | undefined,
        body: any,
    ): Promise<any> {
        return (
            axiosInstance
                // `/api/admin/user/clone/${userId ? userId : ''}`
                .post(`/api/admin/user/clone/`, body, {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((res) => {
                    return res.data;
                })
        );
    }
}
