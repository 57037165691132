export enum StatisticType {
    DATE_CREATED = 'DATE_CREATED',
    CALL_DURATION_ONE_MINUTE = 'CALL_DURATION_ONE_MINUTE',
    QUALIFICATION_CALL = 'QUALIFICATION_CALL',
    QUALIFICATION_CALL_NO_SHOW = 'QUALIFICATION_CALL_NO_SHOW',
    QUALIFICATION_CALL_SHOW = 'QUALIFICATION_CALL_SHOW',
    FIRST_SALES_CALL = 'FIRST_SALES_CALL',
    FIRST_SALES_CALL_NO_SHOW = 'FIRST_SALES_CALL_NO_SHOW',
    FIRST_SALES_CALL_SHOW = 'FIRST_SALES_CALL_SHOW',
    SECOND_SALES_CALL = 'SECOND_SALES_CALL',
    SECOND_SALES_CALL_NO_SHOW = 'SECOND_SALES_CALL_NO_SHOW',
    SECOND_SALES_CALL_SHOW = 'SECOND_SALES_CALL_SHOW',
    CALL_DURATION_ONE_MINUTE_QUALIFICATION_CALL_RATE = 'CALL_DURATION_ONE_MINUTE_QUALIFICATION_CALL_RATE',
    QUALIFICATION_CALL_SHOW_RATE = 'QUALIFICATION_CALL_SHOW_RATE',
    QUALIFICATION_CALL_TO_SALES_CALL_RATE = 'QUALIFICATION_CALL_TO_SALES_CALL_RATE',
    FIRST_SALES_CALL_SHOW_RATE = 'FIRST_SALES_CALL_SHOW_RATE',
    SECOND_SALES_CALL_SHOW_RATE = 'SECOND_SALES_CALL_SHOW_RATE',
    WON_OPPORTUNITIES = 'WON_OPPORTUNITIES',
    LOST_OPPORTUNITIES = 'LOST_OPPORTUNITIES',
    SALES_CALL_WIN_RATE = 'SALES_CALL_WIN_RATE',
    CALLS = 'CALLS',
    OUTBOUND_CALLS = 'OUTBOUND_CALLS',
    ANSWERED_CALLS = 'ANSWERED_CALLS',
    AVG_FIRST_PAYMENT = 'AVG_FIRST_PAYMENT',
}

export const RateToOriginMapping: { [key: string]: string[] } = {
    [StatisticType.CALL_DURATION_ONE_MINUTE_QUALIFICATION_CALL_RATE]: [
        StatisticType.QUALIFICATION_CALL,
        StatisticType.CALL_DURATION_ONE_MINUTE,
    ],
    [StatisticType.QUALIFICATION_CALL_SHOW_RATE]: [
        StatisticType.QUALIFICATION_CALL_SHOW,
        StatisticType.QUALIFICATION_CALL,
    ],
    [StatisticType.QUALIFICATION_CALL_TO_SALES_CALL_RATE]: [
        StatisticType.FIRST_SALES_CALL,
        StatisticType.QUALIFICATION_CALL_SHOW,
    ],
    [StatisticType.FIRST_SALES_CALL_SHOW_RATE]: [
        StatisticType.FIRST_SALES_CALL_SHOW,
        StatisticType.FIRST_SALES_CALL,
    ],
    [StatisticType.SECOND_SALES_CALL_SHOW_RATE]: [
        StatisticType.SECOND_SALES_CALL_SHOW,
        StatisticType.SECOND_SALES_CALL,
    ],
    [StatisticType.SALES_CALL_WIN_RATE]: [
        StatisticType.WON_OPPORTUNITIES,
        StatisticType.FIRST_SALES_CALL_SHOW,
    ],
};

export const OverrideRateToOriginMapping: {
    [key: string]: { [key: string]: string[] };
} = {
    pflegefinanzen: {
        [StatisticType.SALES_CALL_WIN_RATE]: [
            StatisticType.WON_OPPORTUNITIES,
            StatisticType.SECOND_SALES_CALL_SHOW,
        ],
    },
};
