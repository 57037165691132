import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    TextField,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Navbar } from '../../components';
import { loadTitle, setDocumentTitle } from '../../utils/misc-utils';
import { useQueryClient } from 'react-query';
import { AdminApi } from '../../api';
import { AppContext } from '../../hooks/context';

const CloneClose = () => {
    const context = useContext(AppContext);
    const [toApiKey, setToApiKey] = useState('');
    const [loading, setLoading] = useState(false);
    const [leadStatuses, setLeadStatuses] = useState(true);
    const [opportunityStatuses, setOpportunityStatuses] = useState(true);
    const [leadCustomFields, setLeadCustomFields] = useState(true);
    const [oppCustomFields, setOppCustomFields] = useState(true);
    const [contactCustomFields, setContactCustomFields] = useState(true);
    const [customActivities, setCustomActivities] = useState(true);
    const [smartViews, setSmartViews] = useState(true);
    const [templates, setTemplates] = useState(true);
    const [sequences, setSequences] = useState(true);
    const [integrationLinks, setIntegrationLinks] = useState(true);
    const [roles, setRoles] = useState(true);
    const queryClient = useQueryClient();

    const fields = [
        {
            value: leadStatuses,
            change: () => setLeadStatuses(!leadStatuses),
            label: 'Lead Statuses',
        },
        {
            value: opportunityStatuses,
            change: () => setOpportunityStatuses(!opportunityStatuses),
            label: 'Opportunity Statuses',
        },
        {
            value: leadCustomFields,
            change: () => setLeadCustomFields(!leadCustomFields),
            label: 'Lead Custom Fields',
        },
        {
            value: oppCustomFields,
            change: () => setOppCustomFields(!oppCustomFields),
            label: 'Opportunity Custom Fields',
        },
        {
            value: contactCustomFields,
            change: () => setContactCustomFields(!contactCustomFields),
            label: 'Contact Custom Fields',
        },
        {
            value: customActivities,
            change: () => setCustomActivities(!customActivities),
            label: 'Custom Activities',
        },
        {
            value: smartViews,
            change: () => setSmartViews(!smartViews),
            label: 'Smart Views',
        },
        {
            value: templates,
            change: () => setTemplates(!templates),
            label: 'Templates',
        },
        {
            value: integrationLinks,
            change: () => setIntegrationLinks(!integrationLinks),
            label: 'Integration Links',
        },
        {
            value: sequences,
            change: () => setSequences(!sequences),
            label: 'Sequences',
        },
        {
            value: roles,
            change: () => setRoles(!roles),
            label: 'Roles',
        },
    ];

    useEffect(() => {
        setDocumentTitle('Close klonen');
        return () => loadTitle();
    }, []);

    const cloneCloseUser = async () => {
        setLoading(true);
        try {
            await AdminApi.cloneCloseUser(
                context.authToken,
                {
                    toApiKey,
                    leadStatuses,
                    leadCustomFields,
                    smartViews,
                    templates,
                    integrationLinks,
                    sequences,
                    roles,
                    oppCustomFields,
                    opportunityStatuses,
                    contactCustomFields,
                    customActivities,
                },
            );
            queryClient.invalidateQueries('getUsers');
            setLoading(false);
        } catch (e) {
            console.log(e);
            setLoading(false);
        }
    };

    return (
        <Box>
            <Navbar title="Close Daten klonen" hideImpersonate />
            <Box
                display="flex"
                flexDirection="column"
                sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    overflow: 'clip',
                }}
            >
                <TextField
                    color="primary"
                    label="Close API Key des Zielaccounts"
                    size="medium"
                    sx={{ mt: 2, width: '22rem' }}
                    type="text"
                    value={toApiKey}
                    helperText={
                        toApiKey !== '' && !/(api_.{10}).*/.test(toApiKey)
                            ? 'Ungültiger API Key'
                            : ''
                    }
                    error={toApiKey !== '' && !/(api_.{10}).*/.test(toApiKey)}
                    onChange={(e) => {
                        setToApiKey(e.currentTarget.value.trim());
                    }}
                />
                {fields.map((f, index) => (
                    <Box key={index} display="flex" justifyContent="start">
                        <FormControl>
                            <FormControlLabel
                                checked={f.value}
                                onClick={() => f.change()}
                                control={<Checkbox />}
                                label={f.label}
                            />
                        </FormControl>
                    </Box>
                ))}
                <Box
                    display="flex"
                    justifyContent="center"
                    flexDirection="column"
                    sx={{ mt: 2 }}
                >
                    {!loading ? (
                        <Button
                            color="primary"
                            variant="contained"
                            id="loginBtn"
                            onClick={() => cloneCloseUser()}
                            className="formBtn"
                            disabled={
                                toApiKey === '' ||
                                !/(api_.{10}).*/.test(toApiKey)
                            }
                        >
                            Klonen starten (dauert bis zu 10 Minuten)
                        </Button>
                    ) : (
                        <CircularProgress />
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default CloneClose;
