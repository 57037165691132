import {
    faPenToSquare,
    faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton, Tooltip } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { AdminApi } from '../../../api';
import { /* CloneCloseUserDialog, */ Navbar } from '../../../components';
import CreateEditUserDialog, {
    User,
} from '../../../components/admin/create-edit-user-dialog';
import { AppContext } from '../../../hooks/context';
import { loadTitle, setDocumentTitle } from '../../../utils/misc-utils';

interface DataGridElement {
    id: string;
    username: string;
    isAdmin: boolean;
}

const UserList = () => {
    const context = useContext(AppContext);
    const [createEditUserOpen, setCreateEditUserOpen] = useState(false);
    const [rows, setRows] = useState<DataGridElement[]>([]);
    const [totalRowCount, setTotalRowCount] = useState(10);
    const [selectedUser, setSelectedUser] = useState<User>();

    useEffect(() => {
        setDocumentTitle('Benutzerverwaltung');
        return () => loadTitle();
    }, []);

    const { isLoading, error, data } = useQuery({
        queryKey: ['getUsers'],
        queryFn: () => AdminApi.getUsers(context.authToken),
        enabled: context.authToken !== undefined && context.isAdmin,
    });

    useEffect(() => {
        if (error) {
            console.error(error);
        } else if (data && data.data && data.data.users) {
            setRows(data.data.users);
            setTotalRowCount(data.data.users.length);
        }
    }, [data, error]);

    const columns: GridColDef[] = [
        {
            field: 'username',
            headerName: 'Benutzername',
            editable: false,
            flex: 1,
        },
        {
            field: 'role',
            headerName: 'Rolle',
            editable: false,
            flex: 1,
        },
        {
            field: 'Aktionen',
            headerName: '',
            flex: 1,
            hideable: false,
            sortable: false,
            renderHeader: () => (
                <Tooltip title="Benutzer erstellen">
                    <IconButton
                        disableRipple
                        color="primary"
                        onClick={() => setCreateEditUserOpen(true)}
                    >
                        <FontAwesomeIcon icon={faPlus} />
                    </IconButton>
                </Tooltip>
            ),
            renderCell: (params) => (
                <Box>
                    <Tooltip title="Benutzer bearbeiten">
                        <IconButton
                            disableRipple
                            color="info"
                            sx={{ height: 39 }}
                            onClick={() => {
                                setSelectedUser(params.row);
                                setCreateEditUserOpen(true);
                            }}
                        >
                            <FontAwesomeIcon icon={faPenToSquare} />
                        </IconButton>
                    </Tooltip>
                </Box>
            ),
        },
    ];

    return (
        <Box>
            <Navbar title="Benutzerverwaltung" hideImpersonate />
            <Box sx={{ height: '100%', mr: 5, ml: 5 }}>
                <DataGrid
                    columns={columns}
                    rows={rows}
                    loading={isLoading}
                    getRowId={(row) => row.id}
                    pageSize={15}
                    rowsPerPageOptions={[15]}
                    rowCount={totalRowCount}
                    autoHeight
                    experimentalFeatures={{ newEditingApi: true }}
                    disableColumnSelector
                    disableColumnFilter
                    disableColumnMenu
                />
            </Box>
            <CreateEditUserDialog
                open={createEditUserOpen}
                onClose={() => {
                    setCreateEditUserOpen(false);
                    setSelectedUser(undefined);
                }}
                isEdit={selectedUser ? true : false}
                existingUser={selectedUser}
            />
            {/* <CloneCloseUserDialog
                open={cloneCloseUserDialogOpen}
                onClose={() => setCloneCloseUserDialogOpen(false)}
                userId={selectedUser ? selectedUser.id : ''}
            /> */}
        </Box>
    );
};

export default UserList;
