import { FC } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { appHistory } from '../utils/constants';
import { HistoryBrowserRouter } from '../utils/history-router';
import CloneClose from '../views/admin/clone-close';
import PlectoInfo from '../views/plecto-info/plecto-info';
import { Login, UserList } from '../views';
import AdminRoute from './admin-route';
import AuthenticatedRoute from './authenticated-route';

const App: FC = () => {
    return (
        <HistoryBrowserRouter history={appHistory}>
            <Routes>
                <Route path="*" element={<AuthenticatedRoute />}>
                    <Route path="admin" element={<AdminRoute />}>
                        <Route path="users" element={<UserList />} />
                        <Route path="clone" element={<CloneClose />} />
                    </Route>
                    <Route path="*" element={<Navigate to="/admin/clone" />} />
                </Route>
                <Route path="plecto" element={<PlectoInfo />} />
                <Route path="admin/login" element={<Login />} />
            </Routes>
        </HistoryBrowserRouter>
    );
};

export default App;
