import { Box, Button, CircularProgress } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoginApi } from '../../api';
import { LoginInput, Navbar } from '../../components';
import { AppContext } from '../../hooks/context';
import { loadTitle, setDocumentTitle } from '../../utils/misc-utils';

const Login = () => {
    const context = useContext(AppContext);
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);

    // useEffect(() => {
    //     if (context.isAuthenticated) {
    //         navigate('/admin/clone');
    //     }
    // }, [context.isAuthenticated, navigate]);

    useEffect(() => {
        setDocumentTitle('Admin Login');
        return () => loadTitle();
    }, []);

    const signIn = async () => {
        setLoading(true);
        try {
            const res = await LoginApi.login({ username, password });
            if (res) {
                context.authenticate(res.data.token);
                setTimeout(() => navigate('/admin/clone'), 100);
            }
        } catch (e) {
            setLoading(false);
        }
    };

    return (
        <Box>
            <Navbar title="Admin Login" />
            <Box
                display="flex"
                flexDirection="column"
                sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '50vh',
                }}
            >
                <LoginInput
                    onChange={setUsername.bind(this)}
                    label="Benutzername"
                    initialValue={username}
                />
                <LoginInput
                    label="Passwort"
                    onChange={setPassword.bind(this)}
                    password
                    onEnter={() =>
                        (username !== '' || password !== '') && signIn()
                    }
                />
                <Box
                    display="flex"
                    justifyContent="center"
                    flexDirection="column"
                    sx={{ mt: 2 }}
                >
                    {!loading ? (
                        <Button
                            color="primary"
                            variant="contained"
                            id="loginBtn"
                            onClick={() => signIn()}
                            className="formBtn"
                            disabled={username === '' || password === ''}
                        >
                            Einloggen
                        </Button>
                    ) : (
                        <CircularProgress />
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default Login;
